import { Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { AuthenticatedResult, AuthOptions, LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client'
import { map, Observable } from 'rxjs'
import { ConfigId } from 'src/app/login/models/config-id.model'
import { TokenType } from 'src/app/login/models/token-type.model'
import { Logger } from 'src/app/utils/logger'
import { RedirectTo } from 'src/app/utils/redirect-to'
import { environment } from 'src/environments/environment'
import { Features } from '../models/features.model'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private static readonly CONFIG_ID_STORAGE_KEY = 'configId'
  private jwtHelperService: JwtHelperService = new JwtHelperService()
  private loginResponse: LoginResponse | null = null

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private logger: Logger,
    private features: Features
  ) { }

  public get getAccessToken(): string | null {
    return this.loginResponse?.accessToken ?? null
  }

  public get isAuthenticated(): boolean {
    return this.loginResponse?.isAuthenticated ?? false
  }

  public get checkAuth(): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth(undefined, this.configId).pipe(
      map(response => {
        this.logger.debug('Login response:', response)
        this.loginResponse = response
        return response
      })
    )
  }

  public get isAuthenticated$(): Observable<AuthenticatedResult> {
    return this.oidcSecurityService.isAuthenticated$
  }

  public get tokenType(): TokenType | undefined {
    if (environment.envName === 'mock') {
      return this.configId === ConfigId.business ? TokenType.BusinessMitId : TokenType.PrivateMitId
    }
    const idp = this.jwtHelperService.decodeToken(this.getAccessToken)?.idp
    switch (idp) {
    case 'mitid': return TokenType.PrivateMitId
    case 'mitid_erhverv': return TokenType.BusinessMitId
    }
    return undefined
  }

  public get configId(): ConfigId | null {
    switch (sessionStorage.getItem(AuthService.CONFIG_ID_STORAGE_KEY)) {
    case ConfigId.business.toString(): return ConfigId.business
    case ConfigId.private.toString(): return ConfigId.private
    default: return null
    }
  }

  public get isBusinessCredentialsLogin(): boolean {
    return this.tokenType && this.tokenType === TokenType.BusinessCredentials
  }

  public get isPrivateCustomer(): boolean {
    return this.tokenType && (
      this.tokenType === TokenType.PrivateMitId) ? true : false
  }

  public setConfigId(id: ConfigId) {
    sessionStorage.setItem(AuthService.CONFIG_ID_STORAGE_KEY, id.toString())
  }

  public authorize(authOptions?: AuthOptions) {
    this.oidcSecurityService.authorize(this.configId, authOptions)
  }

  public logoffAndRevokeTokens() {
    this.oidcSecurityService.logoff(this.configId).subscribe(_ => {
      if (!this.isAuthenticated) {
        RedirectTo.loginLandingPage()
      }
    })
  }

  public get enableProductionDevelopment(): boolean {
    return !this.isPrivateCustomer && this.features.enableProductionDevelopment
  }
}
