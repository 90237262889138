import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { UserSessionIdService } from '../services/user-session-id.service'

@Injectable()
export class UserSessionInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    return handler.handle(
      this.isEvidaApiRequest(req)
        // Set the session id in the headers if the request is to a MitEvida endpoint
        ? this.setSessionIdInHeader(req)
        // Forward the request if it is not
        : req
    )
  }

  private isEvidaApiRequest(req: HttpRequest<any>): boolean {
    return req.urlWithParams.startsWith(environment.API_BASE_PATH)
  }

  private setSessionIdInHeader(req: HttpRequest<any>): HttpRequest<any> {
    const sessionId = UserSessionIdService.getSessionId()
    const newHeaders = req.headers
      .set(UserSessionIdService.userSessionHeaderKey, sessionId)

    return req.clone({
      headers: newHeaders
    })
  }
}
